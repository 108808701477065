import React, { useEffect, useState } from "react";
import {
  cancelPointChange,
  changePoint,
  getAllShopPointInfo,
  getCafe24Code,
} from "../../../service/Shop";
import { HeaderContainer } from "../../../Accounts";
import BaseTable from "../../../components/Table/BaseTable";
import { ColumnsType } from "antd/es/table";
import { getCoinInfoObjType } from "../../../service/CoinInfo";
import { convertCoinInfoFunc } from "../../../utils";
import { Badge, Button, Space } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import useModalHandler from "../../../hooks/useModalHandler";
import BaseModal from "../../../components/Modal/BaseModal";
import { DeleteFontStyle } from "../../../styles/common";

const ShopPointPage = () => {
  const [code, setCode] = useState("");
  const [pointLists, setPointLists] = useState<ShopPointInfoResBody[]>([]);
  const [coinInfo, setCoinInfo] = useState<{ [key: string]: string }>({});
  const [pt, setPt] = useState(0);
  const [convertCoinInfo, setConvertCoinInfo] = useState<{
    [key: string]: string;
  }>({});

  const [uid, setUid] = useState("");
  const [hidx, setHidx] = useState(-1);
  const [coinidx, setCoinidx] = useState(-1);
  const [type, setType] = useState<"increase" | "decrease">("increase");
  const [memberId, setMemberId] = useState("");

  const [cancelModal, onChangeCancelModal, setCancelModal] =
    useModalHandler(false);

  const [updateModal, onChangeUpdateModal, setUpdateModal] =
    useModalHandler(false);

  const router = useHistory();

  const columns: ColumnsType<ShopPointInfoResBody> = [
    { key: "uid", dataIndex: "uid", title: "uid", align: "center" },
    {
      key: "email",
      dataIndex: "email",
      title: "email",
      align: "center",
    },
    {
      key: "memberId",
      dataIndex: "memberId",
      title: "memberId",
      align: "center",
    },
    {
      key: "coinidx",
      dataIndex: "coinidx",
      title: "coin",
      align: "center",
      width: 100,
      render: (_, coin) => <>{coinInfo ? coinInfo[coin.coinidx] : ""}</>,
    },
    {
      key: "pt",
      dataIndex: "pt",
      title: "변환 할 포인트",
      align: "center",
    },
    {
      key: "changed",
      dataIndex: "changed",
      title: "변경여부",
      align: "center",
      render: (changed) =>
        changed ? (
          <Badge color="green" text="완료" />
        ) : (
          <Badge color="red" text="미완료" />
        ),
    },
    {
      key: "completed",
      dataIndex: "completed",
      title: "완료처리 여부",
      align: "center",
      render: (changed) =>
        changed ? (
          <Badge color="green" text="완료" />
        ) : (
          <Badge color="red" text="미완료" />
        ),
    },
    {
      key: "timestamp",
      dataIndex: "timestamp",
      title: "처리시간",
      align: "center",
      render: (time) => <>{moment(time).format("YYYY-MM-DD HH:mm:ss")}</>,
    },
    {
      key: "delete",
      title: "변환",
      align: "center",
      render: (_, shop) => (
        <Space size="middle">
          {shop.completed ? (
            <div style={{ color: "gray", cursor: "not-allowed" }}>
              <span>변환</span> <span>취소</span>
            </div>
          ) : (
            <>
              <a
                onClick={() => {
                  setUid(shop.uid);
                  setHidx(shop.hidx);
                  setCoinidx(shop.coinidx);
                  setPt(Math.floor(Number(shop.pt)));
                  setType("increase");
                  setMemberId(shop.memberId);
                  onChangeUpdateModal();
                }}
              >
                변환
              </a>
              <a
                onClick={() => {
                  setUid(shop.uid);
                  setHidx(shop.hidx);
                  setCoinidx(shop.coinidx);
                  onChangeCancelModal();
                }}
              >
                취소
              </a>
            </>
          )}
        </Space>
      ),
    },
  ];

  const cancelPointChangeHandler = async () => {
    try {
      if (uid && hidx) await cancelPointChange(uid, hidx, coinidx);
      await getAllShopPointInfo().then(setPointLists);
      alert("정상적으로 취소 했습니다.");
      onChangeCancelModal();
    } catch (err) {
      console.log(err);
      alert("실패했습니다.");
    }
  };

  const changePointHandler = async () => {
    const body = { uid, hidx, coinidx, type, pt: String(pt), memberId };
    try {
      if (uid && hidx && coinidx && memberId) await changePoint(body);
      await getAllShopPointInfo().then(setPointLists);
      alert("정상적으로 반환 했습니다.");
      onChangeUpdateModal();
    } catch (err) {
      alert("실패했습니다.");
    }
  };

  useEffect(() => {
    bootstrap();
  }, []);

  const bootstrap = async () => {
    await getAllShopPointInfo().then(setPointLists);
    const conInfoResult = await getCoinInfoObjType();
    setCoinInfo(conInfoResult);
    setConvertCoinInfo(convertCoinInfoFunc(conInfoResult));
  };

  return (
    <>
      <HeaderContainer>
        <h2>포인트 관리</h2>
      </HeaderContainer>

      <div>
        <BaseTable
          datasource={pointLists.map((p) => ({ key: p.idx, ...p }))}
          columnType={columns}
        />
      </div>

      <BaseModal
        isVisible={cancelModal}
        handleOk={cancelPointChangeHandler}
        handleCancel={onChangeCancelModal}
        headerTitle="취소"
      >
        <DeleteFontStyle>
          정말로 <strong>취소</strong>하시겠습니까?{" "}
        </DeleteFontStyle>
      </BaseModal>

      <BaseModal
        isVisible={updateModal}
        handleOk={changePointHandler}
        handleCancel={onChangeUpdateModal}
        headerTitle="취소"
      >
        <DeleteFontStyle>
          정말로 <strong>변환</strong>하시겠습니까?{" "}
        </DeleteFontStyle>
      </BaseModal>
    </>
  );
};

export default ShopPointPage;
