import React, { useEffect, useState } from "react";
import {
  deleteCoinManager,
  getAllCoinManagers,
} from "../../../service/CoinManager";
import { getCoinInfoIdxes } from "../../../service/CoinInfo";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { parseDateTime } from "../../../utils/date";
import { Button, Space } from "antd";
import { HeaderContainer } from "../../../Accounts";
import BaseTable from "../../../components/Table/BaseTable";
import useModalHandler from "../../../hooks/useModalHandler";
import AddCoinManagerModal from "../../../components/Modal/CoinManager/add";
import {
  CoinInfoIdxResBody,
  CoinManagersResBody,
} from "../../../types/coin_mangers";
import ModifyCoinManagerModal from "../../../components/Modal/CoinManager/modify";
import DeleteBaseModal from "../../../components/Modal/BaseModal/deleteBaseModal";
import { antdTableFilterProperty } from "../../../components/antd/tableFilter";
import { SearchOutlined } from "@ant-design/icons";

const authorizeCoinObj = {
  0: "권한없음",
  1: "주소 조회",
  2: "트랜잭션 조회",
  3: "주소 조회, 트랜젝션 조회",
  4: "지갑잠금 설정",
  5: "주소 조회, 지갑잠금 설정",
  6: "트랜잭션 조회, 지갑잠금 설정",
  7: "all(주소, 트랜젝션, 지갑잠금)",
};

const AdminWalletPage = () => {
  const [coinMangers, setCoinManagers] = useState<CoinManagersResBody[]>([]);
  const [coinIdx, setCoinIdx] = useState<CoinInfoIdxResBody[]>([]);
  const [idx, setIdx] = useState(0);

  const [searchText, setSearchText] = useState<any>("");
  const [searchedColumn, setSearchColumn] = useState<any>(null);

  const [searchEmail, setSearchEmail] = useState<any>("");
  const [searchedEmailColumn, SetSearchedEmailColumn] = useState<any>("");

  const [isVisibleAdd, onChangeAdd, setIsVisibleAdd] = useModalHandler(false);
  const [isVisibleModify, onChangeModify, setIsVisibleModify] = useModalHandler(
    false
  );

  const [isVisibleDelete, onChangeDelete, setIsVisibleDelete] = useModalHandler(
    false
  );

  const onModifyHandler = (id: number) => () => {
    setIdx(id);
    onChangeModify();
  };

  const onDeleteBtnClick = (id: number) => () => {
    setIdx(id);
    onChangeDelete();
  };

  const onDeleteHandler = async () => {
    try {
      if (idx) {
        await deleteCoinManager(idx);
        alert("정상적으로 삭제 되었습니다.");
        window.location.reload();
      }
    } catch (err) {
      alert(`삭제에 실패했습니다.${err}`);
    }
  };

  const adminAccountColumn: ColumnsType<CoinManagersResBody> = [
    {
      key: "name",
      dataIndex: "name",
      title: "이름",
      align: "center",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        antdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "name",
          setSearchText,
          setSearchColumn
        ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        if (record["name"]) {
          const str = value.toString();
          return record["name"].toString().includes(str);
        } else return false;
      },
    },
    {
      key: "email",
      dataIndex: "email",
      title: "이메일",
      align: "center",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        antdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "email",
          setSearchEmail,
          SetSearchedEmailColumn
        ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        if (record["email"]) {
          const str = value.toString();
          return record["email"].toString().includes(str);
        } else return false;
      },
    },

    {
      key: "authorities",
      dataIndex: "authorities",
      title: "권한",
      align: "center",
      render: (_, au) => <>{authorizeCoinObj[au.authorities]} </>,
    },
    {
      key: "name_ko",
      dataIndex: "name_ko",
      title: "코인이름",
      align: "center",
    },
    {
      key: "timestamp",
      dataIndex: "timestamp",
      title: "가입일",
      align: "center",
      sorter: (prev, next) =>
        moment(prev.timestamp).unix() - moment(next.timestamp).unix(),
      render: (value) => <span>{parseDateTime(value)}</span>,
    },
    {
      key: "delete",
      title: "수정 / 삭제",
      align: "center",
      render: (_, coin) => (
        <Space size="middle">
          <a onClick={onModifyHandler(coin.idx)}>수정</a>
          <a onClick={onDeleteBtnClick(coin.idx)}>삭제</a>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    bootstrap();
  }, []);

  const bootstrap = async () => {
    await getAllCoinManagers().then(setCoinManagers);
    await getCoinInfoIdxes().then(setCoinIdx);
  };

  return (
    <>
      <HeaderContainer>
        <h2>관리 수({coinMangers.length})</h2>
        <Button onClick={onChangeAdd}>생성</Button>
      </HeaderContainer>

      <div>
        <BaseTable
          datasource={coinMangers.map((a) => ({ key: a.idx, ...a }))}
          columnType={adminAccountColumn}
        />
      </div>

      <AddCoinManagerModal
        coinIdxArr={coinIdx}
        isVisible={isVisibleAdd}
        modalHandler={onChangeAdd}
      />

      <ModifyCoinManagerModal
        isVisible={isVisibleModify}
        modalHandler={onChangeModify}
        idx={idx}
      />

      <DeleteBaseModal
        onSubmitHandler={onDeleteHandler}
        title={"삭제"}
        isVisible={isVisibleDelete}
        modalHandler={onChangeDelete}
      />
    </>
  );
};

export default AdminWalletPage;
