import React, { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { Badge, Button, Image, Space } from "antd";
import moment from "moment";
import jwt_decode from "jwt-decode";

import LoadingPage from "../../components/Loading";

import BaseTable from "../../components/Table/BaseTable";
import AddHomeModal from "../../components/Modal/home/addHome";
import DeleteHomeModal from "../../components/Modal/home/deleteHome";
import ModifyHomeModal from "../../components/Modal/home/modifyHome";
import useModalHandler from "../../hooks/useModalHandler";
import {getAllHomes} from "../../service/Home";
import {HeaderContainer} from "../../Accounts";

const HomePage = () => {
    const [homes, setHomes] = useState<HomeResBody[]>([]);
    const [loading, setLoading] = useState(false);
    const [homeId, setHomeId] = useState<number | null>(null);
    const [
        isVisibleAddHomeModal,
        onChangeAddHomeModal,
        setIsVisibleAddHomeModal,
    ] = useModalHandler(false);

    const [
        isVisibleDeleteHomeModal,
        onChangeDeleteHomeModal,
        setIsVisibleDeleteHomeModal,
    ] = useModalHandler(false);

    const [
        isVisibleUpdateHomeModal,
        onChangeUpdateHomeModal,
        setIsVisibleUpdateHomeModal,
    ] = useModalHandler(false);

    const onDeleteBtnClick = (id: number) => () => {
        setHomeId(id);
        onChangeDeleteHomeModal();
    };

    const onModifyBtnClick = (id: number) => () => {
        setHomeId(id);
        onChangeUpdateHomeModal();
    };

    useEffect(() => {
        bootstrap();
    }, []);

    const homeColumn: ColumnsType<HomeResBody> = [
        { key: "home_no", dataIndex: "home_no", title: "id", width: 50, align: "center" },
        {
            key: "lang",
            dataIndex: "lang",
            title: "언어",
            width: 100,
            align: "center",
        },
        {
            key: "uid",
            dataIndex: "uid",
            title: "uid",
            width: 100,
            align: "center",
        },
        {
            key: "home_title",
            dataIndex: "home_title",
            title: "제목",
            width: 200,
            align: "center",
        },
        {
            key: "home_content",
            dataIndex: "home_content",
            title: "내용",
            width: 400,
            align: "center",
        },
        {
            key: "video",
            dataIndex: "video",
            title: "파일",
            align: "center",
            width: 300,
            render: (v) => 
              v.slice(-3) == 'jpg' || v.slice(-3) == 'png' || v.slice(-4) == 'jpeg' ? ( <Image src={v} />) : (v),
        },
        {
            key: "is_disp",
            dataIndex: "is_disp",
            title: "게시 여부",
            width: 150,
            align: "center",
            render: (val) =>
                val ? (
                    <Badge color="green" text="활성화" />
                ) : (
                    <Badge color="red" text="비활성화" />
                ),
        },
        {
            key: "modify",
            title: "수정 / 삭제",
            width: 150,
            align: "center",
            render: (_, b) => (
                <Space size="middle">
                    <a onClick={onModifyBtnClick(b.home_no)}>수정</a>
                    <a onClick={onDeleteBtnClick(b.home_no)}>삭제</a>
                </Space>
            ),
        },
    ];

    const bootstrap = async () => {
        setLoading(true);
        await getAllHomes().then(setHomes);
        setLoading(false);
    };

    if (loading) return <LoadingPage loading={loading} />;

    return (
        <>
            <HeaderContainer>
                <h2>홈 게시글({homes.length})</h2>
                <Button onClick={onChangeAddHomeModal}>홈 게시글 추가</Button>
            </HeaderContainer>

            <div>
                <BaseTable
                    datasource={homes.map((b, i) => ({ key: String(i), ...b }))}
                    columnType={homeColumn}
                />
            </div>
            <AddHomeModal
                isVisible={isVisibleAddHomeModal}
                modalHandler={onChangeAddHomeModal}
            />

            <DeleteHomeModal
                isVisible={isVisibleDeleteHomeModal}
                modalHandler={onChangeDeleteHomeModal}
                id={homeId}
            />

            <ModifyHomeModal
                isVisible={isVisibleUpdateHomeModal}
                modalHandler={onChangeUpdateHomeModal}
                id={homeId}
            />
        </>
    );
};

export default HomePage;

