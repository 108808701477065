import React, { Dispatch, SetStateAction } from "react";
import { ColumnsType } from "antd/es/table";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

import { Table } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { CoinManagersResBody } from "../../types/coin_mangers";

type DataSource =
  | AccountResBody
  | AdminAccountReqBody
  | TopBannerResBody
  | CoinManagersResBody
  | Transaction
  | UserCoinBalanceLockResType
  | ShopPointInfoResBody
  | HomeResBody
  | NewsResBody;
// | FeedReqBody
// | PixelCategoryRes
// | PixShopRes
// | MainContentsResBody
// | EventPopupInfoResBody
// | TopBannerResBody;

interface Props<T> {
  datasource: T[];
  columnType: ColumnsType<T>;
  bordered?: boolean;
  sort?: boolean;
  setDatasource?: Dispatch<SetStateAction<string[]>>;
  scroll?: boolean;
  isPagination?: boolean;
  paginationSize?: string;
  onChangePagination?: (e: number) => void;
  onChange?: boolean;
}

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableWrapper = SortableContainer((props) => <tbody {...props} />);

const BaseTable: <T extends DataSource>(
  p: React.PropsWithChildren<Props<T>>
) => React.ReactElement<Props<T>> = ({
  datasource,
  columnType,
  bordered = true,
  sort = false,
  setDatasource,
  scroll = false,
  isPagination,
  paginationSize,
  onChangePagination,
  onChange,
}) => {
  // const onSortEnd = async ({ oldIndex, newIndex }) => {
  //   if (oldIndex !== newIndex) {
  //     console.log(oldIndex, newIndex);
  //     const data = datasource as any;
  //
  //     const body = {
  //       prev: {
  //         idx: data[oldIndex]["idx"],
  //         category: data[newIndex]["category"],
  //       },
  //       next: {
  //         idx: data[newIndex]["idx"],
  //         category: data[oldIndex]["category"],
  //       },
  //     };
  //
  //     console.log(body);
  //     try {
  //       await updateCategoryOrder(body);
  //       await getAllCategories().then(setDatasource);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };
  //
  // const DraggableContainer = (props) => (
  //   <SortableWrapper
  //     useDragHandle
  //     disableAutoscroll
  //     helperClass="row-dragging"
  //     onSortEnd={onSortEnd}
  //     {...props}
  //   />
  // );
  //
  // const DraggableBodyRow = ({ className, style, ...restProps }) => {
  //   // function findIndex base on Table rowKey props and should always be a right array index
  //   const data = datasource as any;
  //   const index = data.findIndex(
  //     (x) => (x.category as number) === restProps["data-row-key"]
  //   );
  //   return <SortableItem index={index} {...restProps} />;
  // };

  return (
    <Table
      dataSource={datasource}
      columns={columnType}
      bordered={bordered}
      rowKey={sort ? "category" : null}
      scroll={scroll ? { x: 2300 } : null}
      pagination={
        isPagination
          ? {
              total: parseInt(paginationSize),
              onChange: (e) => {
                onChangePagination(e);
              },
              showSizeChanger: false,
              pageSize: 20,
            }
          : null
      }
      // components={
      //   sort
      //     ? {
      //         body: {
      //           wrapper: DraggableContainer,
      //           row: DraggableBodyRow,
      //         },
      //       }
      //     : null
      // }
    />
  );
};

export default BaseTable;
