import React, { Key, Dispatch, SetStateAction, useEffect } from "react";
import { FilterConfirmProps } from "antd/es/table/interface";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

type ConfirmProps = (param?: FilterConfirmProps | undefined) => void;
type ClearFilterProps = (() => void) | undefined;

const handleSearch = (
  selectedKeys: Key[],
  confirm: () => void,
  dataIndex: string,
  setSearchText: any,
  setSearchColumn: any
) => {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchColumn(dataIndex);
};

const handleReset = (
  clearFilters: (() => void) | undefined,
  setSearchText: any
) => {
  if (clearFilters) {
    clearFilters();
  }
  // setSearchText("");
};

// antd filter drop/down 속성
export const antdTableFilterProperty = (
  setSelectedKeys: (selectKey: Key[]) => void,
  selectedKeys: Key[],
  confirm: ConfirmProps,
  clearFilters: ClearFilterProps,
  dataIndex: string,
  setSearchText: any,
  setSearchColumn: any
) => {
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => {
          return setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() =>
          handleSearch(
            selectedKeys,
            confirm,
            dataIndex,
            setSearchText,
            setSearchColumn
          )
        }
        style={{ width: "200px", display: "block", marginBottom: "10px" }}
      />

      <Space>
        <Button
          type="primary"
          onClick={() =>
            handleSearch(
              selectedKeys,
              confirm,
              "displayName",
              setSearchText,
              setSearchColumn
            )
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, setSearchText)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false });
            setSearchText(selectedKeys[0]);
            setSearchColumn("displayName");
          }}
        >
          Filter
        </Button>
      </Space>
    </div>
  );
};

export const customAntdTableFilterProperty = (
  setSelectedKeys: (selectKey: Key[]) => void,
  selectedKeys: Key[],
  confirm: ConfirmProps,
  clearFilters: ClearFilterProps,
  dataIndex: string,
  setSearchText: any,
  setSearchColumn?: any,
  text?: string
) => {
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={text ? selectedKeys[0] : ""}
        onChange={(e) => {
          setSearchText(e.target.value);
          return setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() =>
          handleSearch(
            selectedKeys,
            confirm,
            dataIndex,
            setSearchText,
            setSearchColumn
          )
        }
        style={{ width: "200px", display: "block", marginBottom: "10px" }}
      />

      <Space>
        <Button
          type="primary"
          onClick={() =>
            handleSearch(
              selectedKeys,
              confirm,
              dataIndex,
              setSearchText,
              setSearchColumn
            )
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>

        <Button
          onClick={() => {
            clearFilters();
            setSearchText("");
            setSearchColumn("");
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  );
};
