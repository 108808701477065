import React, { useEffect, useState, VFC } from "react";
import useInput from "../../../hooks/useInput";
import {
  getCoinManager,
  updateCoinManager,
} from "../../../service/CoinManager";
import { CoinInfoIdxResBody } from "../../../types/coin_mangers";
import { getCoinInfoIdxes } from "../../../service/CoinInfo";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Switch,
} from "antd";
import { convertAuthCalc, convertAuthNumberToArr } from "../../../utils";

const authOptions = [
  { label: "권한 없음", value: "권한 없음" },
  { label: "주소 조회", value: "주소 조회" },
  { label: "트랜잭션 조회", value: "트랜잭션 조회" },
  { label: "지갑잠금", value: "지갑잠금" },
];

const ModifyCoinManagerModal: VFC<CustomModalProps> = ({
  isVisible,
  modalHandler,
  idx,
}) => {
  const [uid, onChangeUid, setUid] = useInput("");
  const [coinName, setCoinName] = useState("");
  const [coinNameCheckBox, setCoinNameCheckBox] = useState<
    { label: string; value: string }[]
  >([]);

  const [coinIdxInfo, setCoinIdxInfo] = useState<CoinInfoIdxResBody[]>([]);
  const [coinIdxValue, setCoinIdxValue] = useState([]);

  const [auth, setAuth] = useState([]);

  const CoinNameCheckBoxComponent = (
    <>
      <Radio.Group
        onChange={(e) => {
          setCoinName(e.target.value);
        }}
        options={coinNameCheckBox}
        value={coinName}
      />
    </>
  );

  const AuthCheckBoxComponent = (
    <Checkbox.Group
      onChange={(e) => {
        console.log(e);
        if (auth.includes("권한 없음") && e.length > 1) {
          setAuth(e.slice(1, e.length));
          return;
        } else if (e.includes("권한 없음")) {
          setAuth(["권한 없음"]);
          return;
        }
        setAuth(e);
      }}
      options={authOptions}
      value={auth}
    />
  );

  const onSubmitHandler = async () => {
    console.log(coinName, auth);
    console.log(convertAuthCalc(auth));
    const body = {
      name_ko: coinName,
      authorities: convertAuthCalc(auth),
    };

    try {
      await updateCoinManager(idx!, body);
      alert("정상적으로 수정 되었습니다.");
      window.location.reload();
    } catch (err) {
      alert("수정에 실패했습니다." + err);
    }
  };

  useEffect(() => {
    bootstrap();
  }, [isVisible, idx]);

  const bootstrap = async () => {
    if (idx > 0) {
      const conInfo = await getCoinInfoIdxes();
      const result = await getCoinManager(idx);
      let coinNameArr = "";
      conInfo.forEach((coin) => {
        if (Object.keys(coin)[0] === String(result.coinIdx)) {
          return (coinNameArr = Object.values(coin)[0]);
        }
      });

      const checkbox = conInfo.map((coin) => {
        return { label: Object.values(coin)[0], value: Object.values(coin)[0] };
      });
      setCoinNameCheckBox(checkbox);
      console.log(coinNameArr, "----", checkbox);
      setCoinName(coinNameArr);
      setAuth(convertAuthNumberToArr(result.authorities));
      setUid(result.uid);
    }
  };

  console.log(auth);

  return (
    <Modal
      title={"상단배너 수정"}
      visible={isVisible}
      onCancel={modalHandler}
      footer={null}
    >
      <Form wrapperCol={{ span: 16 }}>
        <div style={{ marginBottom: "10px" }}>
          <span
            style={{
              width: "50px",
              display: "inline-block",
              marginBottom: "10px",
            }}
          >
            uid:
          </span>
          <Input value={uid} onChange={onChangeUid} disabled />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <span
            style={{
              width: "100px",
              display: "inline-block",
              marginBottom: "10px",
            }}
          >
            코인이름:
          </span>
          {CoinNameCheckBoxComponent}
        </div>

        <div style={{ marginBottom: "10px" }}>
          <span
            style={{
              width: "50px",
              display: "inline-block",
              marginBottom: "10px",
            }}
          >
            권한:
          </span>
          {AuthCheckBoxComponent}
        </div>

        <Form.Item wrapperCol={{ offset: 21, span: 16 }}>
          <Button type="primary" onClick={onSubmitHandler}>
            수정
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModifyCoinManagerModal;
