import React, { useEffect, useState, VFC } from "react";
import { getUserInfo, updateUserInfo } from "../../../service/Account";
import useInput from "../../../hooks/useInput";
import { Button, Col, Form, Image, Input, Modal, Row, Switch, Select } from "antd";

interface Props {
  uid: string;
  isVisible: boolean;
  modalHandler: () => void;
}

const UpdateUserAccountModal: VFC<Props> = ({
  uid,
  isVisible,
  modalHandler,
}) => {
  const [userInfo, setUserInfo] = useState<AccountResBody>(null);
  const [name, onChangeName, setName] = useInput("");
  const [email, onChangeEmail, setEmail] = useInput("");
  const [contact, onChangeContact, setContact] = useInput("");
  const [password, onChangePassword, setPassword] = useInput("");
  const [social, onChangeSocial, setSocial] = useInput("");
  const [verified, setVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [payable, setPayable] = useState(false);
  const [form] = Form.useForm();

  const onChangeAll = () => {
    form.setFieldsValue({
      name,
      email,
      contact,
      password,
    });
  };

  const onFinish = async () => {
    const body = { name, verified, email, contact, password, social, phoneVerified, blocked, payable };
    try {
      if (uid) {
        await updateUserInfo(uid, body);
        alert("정상적으로 수정했습니다.");
        window.location.reload();
      }
    } catch (err) {
      console.log(err.response.data.message);
      alert("수정에 실패했습니다.");
      return;
    }
  };

  useEffect(() => {
    bootstrap();
  }, [isVisible, uid]);

  const bootstrap = async () => {
    if (uid) {
      const result = await getUserInfo(uid);
      setName(result.name);
      setEmail(result.email);
      setContact(result.contact);
      setPassword("");
      // setPassword(result.password);
      setSocial(result.social);
      setVerified(result.verified);
      setBlocked(result.blocked);
      setPayable(result.payable);
      setPhoneVerified(result.phoneVerified);
    }

  };

  return (
    <Modal
      title="유저정보 수정"
      visible={isVisible}
      onCancel={modalHandler}
      footer={null}
    >
      <Form form={form} wrapperCol={{ span: 16 }} onFinish={onFinish}>
        <Row>
          <Col lg={24}>
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                alignItems: "center",
              }}
            >
              <span style={{ width: "60px" }}>이름: </span>
              <Input value={name} onChange={onChangeName} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={24}>
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                alignItems: "center",
              }}
            >
              <span style={{ width: "60px" }}>이메일: </span>
              <Input value={email} onChange={onChangeEmail} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={24}>
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                alignItems: "center",
              }}
            >
              <span style={{ width: "60px" }}>핸드폰: </span>
              <Input value={contact} onChange={onChangeContact} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={24}>
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                alignItems: "center",
              }}
            >
              <span style={{ width: "60px" }}>비번: </span>
              <Input value={password} onChange={onChangePassword} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={24}>
            <Form.Item label="로그인" name="social">
              <Select
                value={social}
                onChange={(value) => setSocial(value)}
                style={{ width: '50%' }} // Adjust width as necessary
              >
                <Select.Option value="normal">normal</Select.Option>
                <Select.Option value="kakaotalk">kakaotalk</Select.Option>
                <Select.Option value="google">google</Select.Option>
                <Select.Option value="facebook">facebook</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={24}>
            <Form.Item label="이메일 인증 " name="verified">
              <Switch
                checked={verified}
                onClick={() => {
                  setVerified((prevState) => !prevState);
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24}>
            <Form.Item label="휴대폰 인증 " name="phoneVerified">
              <Switch
                checked={phoneVerified}
                onClick={() => {
                  setPhoneVerified((prevState) => !prevState);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="결제가능 여부" name="payable">
              <Switch
                checked={payable}
                onClick={() => {
                  console.log(123123);
                  setPayable((prevState) => !prevState);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="활성화 여부" name="enabled">
              <Switch
                checked={!blocked}
                onClick={() => {
                  setBlocked((prevState) => !prevState);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item wrapperCol={{ offset: 21, span: 16 }}>
          <Button type="primary" htmlType="submit">
            수정
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateUserAccountModal;
