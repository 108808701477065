import { Layout } from "antd";
import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { AccountInfoAtom } from "../store/Atoms";
import { getMyInfo } from "../service/Account";

import LOGO from "../assets/images/icon512@3x.png";

function HeaderComponent() {
  const { Header } = Layout;
  const [accountInfo, setAccountInfo] = useRecoilState(AccountInfoAtom);

  useEffect(() => {
    bootstrap();
  }, []);

  const bootstrap = async () => {
    const myInfo = await getMyInfo();
    setAccountInfo({
      email: myInfo.email,
      name: myInfo.name,
      adminId: myInfo.adminId,
      level: myInfo.level,
    });
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.href = '/';
  };

  return (
    <Header className="header">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {/*<img*/}
          {/*  style={{ width: "140px", height: "100%" }}*/}
          {/*  src={LOGO}*/}
          {/*  alt="logo"*/}
          {/*/>*/}
        </div>

        <div>
          <div style={{ display: "flex" }}>
            <h2 style={{ color: "#fff", marginRight: "10px" }}>
              {accountInfo && accountInfo.email}
            </h2>
            <h3 style={{ color: "#fff", marginRight: "30px" }}>
              {accountInfo && accountInfo.name}
            </h3>
            <h2 style={{ color: "#fff", cursor: "pointer" }} onClick={logout}>
              로그아웃
            </h2>
          </div>
        </div>
      </div>
    </Header>
  );
}

export default HeaderComponent;
