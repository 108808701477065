import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import moment from "moment";
import styled from "@emotion/styled";
import { Button } from "antd";
import useModalHandler from "../hooks/useModalHandler";
import { parseDateTime } from "../utils/date";
import { deleteAccount, getAdminAccounts } from "../service/Account";
import LoadingPage from "../components/Loading";
import BaseTable from "../components/Table/BaseTable";
import BaseModal from "../components/Modal/BaseModal";
import AddAdminAccountModal from "../components/Modal/account/AddAdminAccount";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AccountPage = () => {
  const [adminAccounts, setAdminAccounts] = useState<AdminAccountReqBody[]>([]);
  const [accountId, setAccountId] = useState<number | null>(null);
  const [
    isVisibleDeleteModal,
    onChangeDeleteModal,
    setIsVisibleDeleteModal,
  ] = useModalHandler(false);
  const [loading, setLoading] = useState(false);
  const [
    isVisibleAddAccountModal,
    onChangeAddAccountModal,
    setIsVisibleAddAccountModal,
  ] = useModalHandler(false);

  const [
    isVisibleDeleteAccountModal,
    onChangeDeleteAccountModal,
    setIsVisibleDeleteAccountModal,
  ] = useModalHandler(false);

  const onDeleteAccount = (id: number) => async () => {
    setAccountId(id);
    onChangeDeleteModal();
  };

  const adminAccountColumn: ColumnsType<AdminAccountReqBody> = [
    { key: "name", dataIndex: "name", title: "이름", align: "center" },
    { key: "email", dataIndex: "email", title: "이메일", align: "center" },

    {
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      title: "휴대폰 번호",
      align: "center",
    },
    {
      key: "level",
      dataIndex: "level",
      title: "권한",
      align: "center",
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "가입일",
      align: "center",
      sorter: (prev, next) =>
        moment(prev.createdAt).unix() - moment(next.createdAt).unix(),
      render: (value) => <span>{parseDateTime(value)}</span>,
    },
    {
      key: "delete",
      title: "계정 삭제",
      align: "center",
      render: (_, account) => (
        <a onClick={onDeleteAccount(account.adminId)}>삭제</a>
      ),
    },
  ];

  useEffect(() => {
    (async function bootstrap() {
      await getAdminAccounts().then(setAdminAccounts);
    })();
  }, []);
  if (loading) return <LoadingPage loading={loading} />;
  return (
    <>
      <HeaderContainer>
        <h2>어드민계정 수({adminAccounts?.length})</h2>
        <Button onClick={onChangeAddAccountModal}>계정생성</Button>
      </HeaderContainer>
      <div>
        <BaseTable
          datasource={
            adminAccounts &&
            adminAccounts.map((a) => ({ key: a.adminId, ...a }))
          }
          columnType={adminAccountColumn}
        />
      </div>
      <AddAdminAccountModal
        isVisible={isVisibleAddAccountModal}
        modalHandler={onChangeAddAccountModal}
      />
      <BaseModal
        isVisible={isVisibleDeleteModal}
        handleOk={async () => {
          if (accountId) {
            try {
              await deleteAccount(accountId);
              alert("정상적으로 삭제되었습니다.");
              window.location.reload();
            } catch (err) {
              alert("에러가 발생했습니다.");
            }
          }
        }}
        handleCancel={onChangeDeleteModal}
        headerTitle={"계정 삭제"}
      >
        <p style={{ fontSize: "20px" }}>
          정말로 <strong style={{ color: "red" }}>삭제</strong> 하시겠습니까?
        </p>
      </BaseModal>
    </>
  );
};
export default AccountPage;
