import { useEffect, useState, VFC } from "react";
import { Button, Checkbox, Form, Input, Modal, Radio } from "antd";
import { createCoinManager } from "../../../service/CoinManager";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { convertAuthCalc } from "../../../utils";
import {
  CoinInfoIdxResBody,
  CoinManagersReqBody,
} from "../../../types/coin_mangers";

interface Props extends CustomModalProps {
  coinIdxArr: CoinInfoIdxResBody[];
}

export const authArr = ["주소 조회", "트랜잭션 조회", "지갑잠금"];

const AddCoinManagerModal: VFC<Props> = ({
  isVisible,
  modalHandler,
  coinIdxArr,
}) => {
  const [firstCoinIdx, setFirstCoinIdx] = useState([]);
  const [coinNameGroup, setCoinNameGroup] = useState<CheckboxValueType[]>([]);
  const [authGroup, setAuthGroup] = useState<CheckboxValueType[]>([]);
  const [coinIdxValue, setCoinIdxValue] = useState([]);
  const onFinish = async (value: CoinManagersReqBody) => {
    console.log(value);
    const authorities = convertAuthCalc(authGroup);
    const body = {
      uid: value.uid,
      name_ko: coinNameGroup,
      authorities,
    };
    console.log(body);

    try {
      await createCoinManager(body);
      alert("정상적으로 생성이 되었습니다.");
      window.location.reload();
    } catch (err) {
      alert("생성에 실패했습니다." + err);
    }
  };

  const CoinNameCheckBoxComponent = (
    <>
      <Checkbox.Group
        onChange={(e) => {
          setCoinNameGroup(e);
        }}
        options={coinIdxValue}
      />
    </>
  );

  const AuthCheckBoxComponent = (
    <Checkbox.Group
      onChange={(e) => {
        setAuthGroup(e);
      }}
      options={authArr}
    />
  );

  useEffect(() => {
    if (coinIdxArr.length > 0) {
      const arr = [];
      const data = Object.values(coinIdxArr[0]);
      coinIdxArr.forEach((co) => {
        console.log(Object.values(co), Object.keys(co));
        arr.push(Object.values(co)[0]);
      });
      console.log(arr);
      setCoinIdxValue(arr);
      setFirstCoinIdx(data);
    }
  }, [JSON.stringify(coinIdxArr)]);

  return (
    <Modal
      title="생성"
      visible={isVisible}
      onCancel={modalHandler}
      footer={null}
    >
      <Form
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
      >
        <Form.Item
          label="uid"
          name="uid"
          rules={[{ required: true, message: "uid 입력하세요!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="코인이름"
          name="coinName"
          rules={[
            {
              required: coinNameGroup.length === 0,
              message: "선택해주세요.",
            },
          ]}
        >
          {CoinNameCheckBoxComponent}
        </Form.Item>

        <Form.Item label="권한" name="auth">
          {AuthCheckBoxComponent}
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "center" }}>
          권한을 선택하지 않으면 자동으로 &nbsp; <strong>권한없음</strong>으로
          설정됩니다.
        </div>

        <Form.Item wrapperCol={{ offset: 21, span: 16 }}>
          <Button type="primary" htmlType="submit">
            생성
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCoinManagerModal;
