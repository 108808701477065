import { AdminApi } from "../index";
import moment from "moment";
const baseUrl = "/withdraw";

export const getAllTransaction = (offset: string, from: string, to: string) => {
  return AdminApi.get<TransactionResBody>(`${baseUrl}`, {
    params: {
      from,
      to,
      offset,
    },
  }).then((resp) => resp.data);
};

export const searchTransactions = (
  offset: string,
  from: string,
  to: string,
  uid: string,
  type: string,
  coinidx: string,
  hash: string,
  address: string,
  source: string
) => {
  console.log(coinidx);
  return AdminApi.get<TransactionResBody>(`${baseUrl}/search`, {
    params: {
      offset,
      from,
      to,
      uid,
      type,
      coinidx: !coinidx ? "" : coinidx,
      hash,
      address,
      source,
    },
  }).then((resp) => resp.data);
};
