export const routes = {
  LOGIN: "/login",
  USER: "/users",
  USER_DASHBOARD: "/users/dashboard",
  NATION_STATISTICS: "/users/dashboard/nation_statistics",
  ACCOUNTS: "/accounts",
  BANNER_TOP: "/banner/top",
  ADMIN_WALLET: "/admin/wallet",
  // HOME: "./",
  HOME: "/home",
  NEWS: "/news",
  TRANSACTION: "/transactions",
  BALANCES: "/admin/balances",
  CAFE24_INFO: "/shop/cafe24/info",
  POINT: "/shop/point",
};

const ADMIN_UID = '0b906c6cdd';
const isProd = process.env.NODE_ENV === "production";
export const ADMIN_API_URL = isProd
  ? "https://admin.api.28vcwallet.com/admin/api"
  : "http://localhost:3065/admin/api";

export const CS_API_URL = isProd
  ? `https://api.28vcwallet.com/cs?uid=${ADMIN_UID}`
  : `http://183.99.33.128:5000/cs?uid=${ADMIN_UID}`;

export const excludeHeaderPage = [routes.LOGIN];

console.log(`서버 요청주소: ${ADMIN_API_URL} 환경: ${process.env.NODE_ENV}`);
