import React, { useEffect, useState } from "react";
import {
  getAllTransaction,
  searchTransactions,
} from "../../service/Transaction";
import moment from "moment";
import { HeaderContainer } from "../../Accounts";
import { ColumnsType } from "antd/es/table";
import {
  antdTableFilterProperty,
  customAntdTableFilterProperty,
} from "../../components/antd/tableFilter";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Image,
  Row,
  Tag,
  DatePicker,
  Card,
  Table,
  Select,
} from "antd";
import { parseDateTime, parsePhoneNumber } from "../../utils/date";
import BaseTable from "../../components/Table/BaseTable";
import { RangePicker } from "rc-picker";
import useInput from "../../hooks/useInput";
import { CoinInfoIdxResBody } from "../../types/coin_mangers";
import { getCoinInfoIdxes, getCoinInfoObjType } from "../../service/CoinInfo";
import { convertCoinInfoFunc } from "../../utils";
import { RecoilValueReadOnly } from "recoil";

const TransactionPage = () => {
  const dateFormat = "YYYY-MM-DD";
  const [total, setTotal] = useState("");
  const [transactions, setTransactions] = useState<TransactionResBody>(null);
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([]);
  const [rangeDefaultDate, setRangeDefaultDate] = useState([
    moment(new Date(), dateFormat),
    moment(new Date(), dateFormat).subtract(6, "months"),
  ]);

  const [type, setType] = useState("");
  const [uid, onChangeUid, setUid] = useInput("");
  const [coinidx, onChangeCoinIdx, setCoinIdx] = useInput("");
  const [source, onChangeSource, setSource] = useInput("");
  const [address, onChangeAddress, setAddress] = useInput("");
  const [hash, onChangeHash, setHash] = useInput("");
  const [paginationOffset, setPaginationOffset] = useState("1");

  const [rangeDate, setRangeDate] = useState<string[]>([]);

  const [searchTxid, setSearchTxid] = useState<any>("");
  const [searchedTxidColumn, SetSearchedTxidColumn] = useState<any>("");
  const [allResetTrigger, setAllResetTrigger] = useState(false);
  const { RangePicker } = DatePicker;

  const [coinInfo, setCoinInfo] = useState<{ [key: string]: string }>({});
  const [convertCoinInfo, setConvertCoinInfo] = useState<{
    [key: string]: string;
  }>({});

  const onChangePagination = async (e: number) => {
    setPaginationOffset(String(e));
    const endDate =
      rangeDate.length > 0
        ? moment(rangeDate[0]).format("YYYY-MM-DD")
        : moment(rangeDefaultDate[0]).format("YYYY-MM-DD");

    const startDate =
      rangeDate.length > 0
        ? moment(rangeDate[1]).format("YYYY-MM-DD")
        : moment(rangeDefaultDate[1]).format("YYYY-MM-DD");
    console.log(coinidx, coinInfo, convertCoinInfo[coinidx]);
    const data = await searchTransactions(
      String(e),
      startDate,
      endDate,
      uid ? uid : "",
      type ? type : "",
      coinidx ? convertCoinInfo[coinidx] : "",
      hash ? hash : "",
      address ? address : "",
      source ? source : ""
    );
    setTotal(data.total);
    setTransactionsData(data.data);
  };

  const onLookUpBtnClickHandler = async () => {
    const endDate =
      rangeDate.length > 0
        ? moment(rangeDate[0]).format("YYYY-MM-DD")
        : moment(rangeDefaultDate[0]).format("YYYY-MM-DD");

    const startDate =
      rangeDate.length > 0
        ? moment(rangeDate[1]).format("YYYY-MM-DD")
        : moment(rangeDefaultDate[1]).format("YYYY-MM-DD");

    const data = await searchTransactions(
      paginationOffset,
      startDate,
      endDate,
      uid ? uid : "",
      type,
      coinidx ? convertCoinInfo[coinidx] : "",
      hash ? hash : "",
      address ? address : "",
      source ? source : ""
    );
    setTransactionsData(data.data);
    setTotal(data.total);
  };

  const onResetSearch = async () => {
    const endDate = moment(new Date()).format(dateFormat);
    const startDate = moment(new Date())
      .subtract(6, "months")
      .format(dateFormat);

    const data = await getAllTransaction(paginationOffset, startDate, endDate);
    setTransactionsData(data.data);
    setTotal(data.total);
    setUid("");
    setType("");
    setCoinIdx("");
    setHash("");
    setAddress("");
    setSource("");
    setType("");
    setAllResetTrigger(true);
    setRangeDate([]);
  };

  const trColumn: ColumnsType<Transaction> = [
    {
      key: "type",
      dataIndex: "type",
      title: "type",
      align: "center",
      width: 100,
      fixed: true,

      render: (type, tr) =>
        tr.type === "1" ? (
          <Tag color="green">입금</Tag>
        ) : (
          <Tag color="red">출금</Tag>
        ),
    },

    {
      key: "uid",
      dataIndex: "uid",
      title: "uid",
      width: 200,
      align: "center",
      fixed: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        customAntdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "uid",
          setUid,
          SetSearchedTxidColumn,
          uid
        ),
      filterIcon: (filtered) => {
        return <SearchOutlined />;
      },
    },
    {
      key: "coinidx",
      dataIndex: "coinidx",
      title: "coin",
      align: "center",
      width: 100,
      fixed: true,
      render: (_, coin) => <>{coinInfo ? coinInfo[coin.coinidx] : ""}</>,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        customAntdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "coinidx",
          setCoinIdx,
          SetSearchedTxidColumn,
          coinidx
        ),
      filterIcon: (filtered) => <SearchOutlined />,
    },
    {
      key: "status",
      dataIndex: "status",
      title: "전송상태",
      align: "center",
      width: 100,
      fixed: true,
      render: (_, tr) => {
        switch (tr.status) {
          case 1:
            return <Badge color="yellow" text="대기" />;
          case 2:
            return <Badge color="orange" text="진행중" />;
          case 4:
            return <Badge color="green" text="완료" />;
          default:
            return <Badge color="red" text="실패" />;
        }
      },
    },
    {
      key: "blockNumber",
      dataIndex: "blockNumber",
      title: "blockNumber",
      align: "center",
      width: 150,
    },
    {
      key: "hash",
      dataIndex: "hash",
      title: "txid",
      align: "center",
      width: "100",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        customAntdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "hash",
          setHash,
          SetSearchedTxidColumn,
          hash
        ),
      filterIcon: (filtered) => <SearchOutlined />,
      render: (_, account) => <>{account.hash}</>,
    },
    {
      key: "source",
      dataIndex: "source",
      title: "send",
      align: "center",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        customAntdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "source",
          setSource,
          SetSearchedTxidColumn,
          source
        ),
      filterIcon: (filtered) => <SearchOutlined />,
    },
    {
      key: "address",
      dataIndex: "address",
      title: "Recv",
      align: "center",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        customAntdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "address",
          setAddress,
          SetSearchedTxidColumn,
          address
        ),
      filterIcon: (filtered) => <SearchOutlined />,
    },
    {
      key: "value",
      dataIndex: "value",
      title: "value",
      align: "center",
      render: (_, coin) => <>{
        coin.type == '0' ? 
            coin.coinidx == 1 ? coin.value :
              Number(coin.value).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') :
            coin.coinidx == 1 ? coin.value : 
              Number(coin.token).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }</>,
    },

    {
      key: "timestamp",
      dataIndex: "timestamp",
      title: "timestamp",
      align: "center",
      width: 200,
      sorter: (prev, next) =>
        moment(prev.timestamp).unix() - moment(next.timestamp).unix(),
      render: (value) => <span>{parseDateTime(value)}</span>,
    },
  ];

  useEffect(() => {
    bootstrap();
  }, []);

  const bootstrap = async () => {
    const endDate = moment(rangeDefaultDate[0]).format("YYYY-MM-DD");

    const startDate = moment(rangeDefaultDate[1]).format("YYYY-MM-DD");
    const conInfoResult = await getCoinInfoObjType();
    setCoinInfo(conInfoResult);
    setConvertCoinInfo(convertCoinInfoFunc(conInfoResult));

    const result = await getAllTransaction("1", startDate, endDate);
    setTotal(result.total);
    setTransactions(result);
    setTransactionsData(result.data);
  };

  return (
    <>
      <HeaderContainer>
        <h2>트렌젝션</h2>
      </HeaderContainer>
      <Card style={{ marginBottom: "20px" }}>
        <Row>
          <Col lg={24}>
            <div
              style={{
                lineHeight: "1.5715",
                color: "rgba(0, 0, 0, 0.45)",
                marginBottom: "20px",
              }}
            >
              기간 선택
            </div>
            <RangePicker
              value={[
                rangeDate.length > 0
                  ? moment(rangeDate[0], dateFormat)
                  : rangeDefaultDate[0],
                rangeDate.length > 0
                  ? moment(rangeDate[1], dateFormat)
                  : rangeDefaultDate[1],
              ]}
              format={dateFormat}
              onChange={(e) => {
                setRangeDate([
                  moment(e[1]).format("YYYY-MM-DD"),
                  moment(e[0]).format("YYYY-MM-DD"),
                ]);
              }}
            />
            <Button
              onClick={onLookUpBtnClickHandler}
              style={{ marginLeft: "20px", marginRight: "20px" }}
            >
              조회하기
            </Button>

            <Button onClick={onResetSearch} type="primary">
              검색 기록 초기화
            </Button>
          </Col>
        </Row>

        <Row>
          <Col lg={24}>
            <div
              style={{
                lineHeight: "1.5715",
                color: "rgba(0, 0, 0, 0.45)",
                marginTop: "20px",
              }}
            >
              type
            </div>

            <Select
              defaultValue="전체"
              style={{ width: 120 }}
              onChange={async (e) => {
                const typeData = e as string;
                setType(typeData);
                const endDate =
                  rangeDate.length > 0
                    ? moment(rangeDate[0]).format("YYYY-MM-DD")
                    : moment(rangeDefaultDate[0]).format("YYYY-MM-DD");

                const startDate =
                  rangeDate.length > 0
                    ? moment(rangeDate[1]).format("YYYY-MM-DD")
                    : moment(rangeDefaultDate[1]).format("YYYY-MM-DD");

                const data = await searchTransactions(
                  paginationOffset,
                  startDate,
                  endDate,
                  uid ? uid : "",
                  typeData,
                  coinidx ? coinidx : "",
                  hash ? hash : "",
                  address ? address : "",
                  source ? source : ""
                );
                setTransactionsData(data.data);
                setTotal(data.total);
              }}
            >
              <Select.Option value="">전체</Select.Option>
              <Select.Option value="1">입금</Select.Option>

              <Select.Option value="0">출금</Select.Option>
            </Select>
          </Col>
        </Row>
      </Card>
      <div>
        <Table
          dataSource={transactionsData.map((t, i) => ({ key: t.idx, ...t }))}
          columns={trColumn}
          bordered={true}
          scroll={{ x: 2300 }}
          pagination={{
            total: parseInt(total),
            onChange: (e) => {
              onChangePagination(e);
            },
            showSizeChanger: false,
            pageSize: 20,
          }}
        />
      </div>
    </>
  );
};

export default TransactionPage;
