import { AdminApi } from "../index";

export function getAllNewss() {
  return AdminApi.get<NewsResBody[]>(`/news`).then(
    (resp) => resp.data
  );
}

export function getNews(id: number) {
  return AdminApi.get<NewsResBody>(`/news/${id}`).then(
    (resp) => resp.data
  );
}

export function addNews(body: NewsReqBody) {
  return AdminApi.post("/news", body);
}

export function modifyNews(body: NewsReqBody, id: number) {
  return AdminApi.put(`/news/${id}`, body).then((resp) => resp.data);
}

export function deleteNews(id: number) {
  return AdminApi.delete(`/news/${id}`).then((resp) => resp.data);
}

export async function uploadImage(file: FormData) {
  return AdminApi.post<{ url: string; filename: string }>(
    "/image/news",
    file
  ).then((resp) => resp.data);
}
