import { Dispatch, SetStateAction, useCallback, useState } from "react";
type Handler = () => void;
type ReturnTypes = [boolean, Handler, Dispatch<SetStateAction<boolean>>];
const useModalHandler = (initialValue: boolean): ReturnTypes => {
  const [value, setValue] = useState(initialValue);
  const handler = useCallback(() => {
    setValue(!value);
  }, [value]);

  return [value, handler, setValue];
};

export default useModalHandler;
