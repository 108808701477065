import { CheckboxValueType } from "antd/es/checkbox/Group";

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// 권한 점수
export const convertAuthCalc = (authArr: CheckboxValueType[]) => {
  console.log(authArr);
  let count = 0;
  authArr.forEach((data) => {
    switch (data) {
      case "주소 조회":
        count += 1;
        break;

      case "트랜잭션 조회":
        count += 2;
        break;

      case "지갑잠금":
        count += 4;
        break;
    }
  });
  return count;
};

// 권한 점수 => arr
export const convertAuthNumberToArr = (count: number): string[] => {
  switch (count) {
    case 0:
      return ["권한 없음"];

    case 1:
      return ["주소 조회"];

    case 2:
      return ["트랜잭션 조회"];

    case 3:
      return ["주소 조회", "트랜잭션 조회"];

    case 4:
      return ["지갑잠금"];

    case 5:
      return ["주소 조회", "지갑잠금"];

    case 6:
      return ["트랜잭션 조회", "지갑잠금"];

    case 7:
      return ["주소 조회", "트랜잭션 조회", "지갑잠금"];
  }
};

export const convertCoinInfoFunc = (coinInfo: { [key: string]: string }) => {
  return Object.entries(coinInfo).reduce(
    (acc, [key, value]) => ((acc[value] = key), acc),
    {}
  );
};
