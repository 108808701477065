import { AdminApi } from "../index";
import { CoinInfoIdxResBody } from "../../types/coin_mangers";

const baseUrl = "/coin-info";

export const getCoinInfoIdxes = () => {
  return AdminApi.get<CoinInfoIdxResBody[]>(`${baseUrl}/idx`).then(
    (resp) => resp.data
  );
};

export const getCoinInfoObjType = () => {
  return AdminApi.get<{ [key: string]: string }>(`${baseUrl}`).then(
    (resp) => resp.data
  );
};

export const getUserCoinLockBalances = (uid: string) => {
  return AdminApi.get<UserCoinBalanceLockResType[]>(
    `${baseUrl}/lock/balances/${uid}`
  ).then((resp) => resp.data);
};

export const getUserCoinLockBalance = (idx: number) => {
  return AdminApi.get<UserCoinBalanceLockResType>(
    `${baseUrl}/lock/${idx}`
  ).then((resp) => resp.data);
};

export const updateUserCoinLockBalance = (
  idx: number,
  body: UserCoinBalanceLockReqType
) => {
  return AdminApi.put(`${baseUrl}/lock/balances/${idx}`, body);
};

export const createUserCoinLockBalance = (body: UserCoinBalanceLockReqType) => {
  return AdminApi.post(`${baseUrl}/lock/balances`, body);
};

export const deleteUserCoinLockBalance = (idx, uid, coinidx) => {
  return AdminApi.delete(
    `${baseUrl}/lock/${idx}/uid/${uid}/coinidx/${coinidx}`
  );
};

export const createUserWalletLock = (body: UserWalletLockReqType) => {
  window.location.reload();
  return AdminApi.post(`${baseUrl}/lock/wallet`, body);
};