import React, { useRef, useState, VFC } from "react";
import { Button, Col, Form, Input, Modal, Row, Switch, Image, Radio } from "antd";
import { addHome, uploadImage } from "../../../service/Home";

export interface CustomModalProps {
  isVisible: boolean;
  modalHandler: () => void;
  id?: number;
}
const AddHomeModal: VFC<CustomModalProps> = ({
  isVisible,
  modalHandler,
}) => {
  const { TextArea } = Input;
  const inputOpenImageRef = useRef<HTMLInputElement>(null);
  const [imageURL, setImageURL] = useState("");
  const [dispChecked, setDispChecked] = useState(true);
  const handleImageClick = () => {
    if (inputOpenImageRef && inputOpenImageRef.current) {
      inputOpenImageRef.current.click();
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const formData = new FormData();
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    formData.append("file", e.target.files[0]);

    try {
      const result = await uploadImage(formData);
      console.log(result, "result");
      const { url } = result;
      setImageURL(url);
    } catch (err) {
      alert("파일 업로드에 실패했습니다.");
    }
  };

  const onFinish = async (value: {
      lang: string,
      uid: string,
      home_title: string,
      home_content: string 
    }) => {
    const { lang= "ko", uid, home_title, home_content } = value;
    const body = {
      lang,
      uid,
      home_title,
      home_content,
      video: imageURL,
      is_disp: dispChecked
    };
    try {
      await addHome(body);
      alert("정상적으로 등록되었습니다.");
      window.location.reload();
    } catch (err) {
      alert("에러가 발생했습니다.");
    }
  };

  return (
    <Modal
      title="홈 게시글 추가"
      visible={isVisible}
      onCancel={modalHandler}
      footer={null}
    >
      <Form name="basic" wrapperCol={{ span: 16 }} onFinish={onFinish}>
        <Form.Item 
          label="언어"
          name="lang"
        >
          <Radio.Group buttonStyle="solid" defaultValue={"ko"}>
            <Radio.Button value="ko">ko</Radio.Button>
            <Radio.Button value="en">en</Radio.Button>
            <Radio.Button value="ja">ja</Radio.Button>
            <Radio.Button value="cn">cn</Radio.Button>
            <Radio.Button value="in">in</Radio.Button>
            <Radio.Button value="vi">vi</Radio.Button>
            <Radio.Button value="th">th</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={<p style={{width:"27px", top:"7px", position:"relative"}}>uid</p>}
          name="uid"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="제목"
          name="home_title"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="내용"
          name="home_content"
        >
          <TextArea rows={4} maxLength={5000} />
        </Form.Item>

        <Form.Item label="파일" name="video">
          {imageURL ? (
            <div style={{ display: "flex" }}>
              {imageURL.slice(-3) == 'png' || imageURL.slice(-3) == 'jpg' || imageURL.slice(-4) == 'jpeg' ? 
                (<Image src={imageURL} />) : (imageURL)}
              <Button
                onClick={() => {
                  setImageURL("");
                }}
              >
              </Button>
            </div>
          ) : (
            <Button onClick={handleImageClick}>파일 업로드</Button>
          )}
        </Form.Item>

        <Row>
          <Col span={12}>
            <Form.Item label="게시 여부" name="is_disp">
              <Switch
                checked={dispChecked}
                onClick={() => {
                  setDispChecked((prevState) => !prevState);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item wrapperCol={{ offset: 21, span: 16 }}>
          <Button type="primary" htmlType="submit">
            생성
          </Button>
        </Form.Item>
      </Form>
      <input
        type="file"
        accept="image/*, video/*"
        style={{ display: "none" }}
        ref={inputOpenImageRef}
        onChange={handleImageUpload}
      />
    </Modal>
  );
};

export default AddHomeModal;
