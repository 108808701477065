import { Button, Form, Input, Modal, Radio } from "antd";
import { VFC } from "react";
import { createAccount } from "../../../service/Account";

interface Props {
  isVisible: boolean;
  modalHandler: () => void;
}

const AddAdminAccountModal: VFC<Props> = ({ isVisible, modalHandler }) => {
  const onFinish = async (value: CreateAdminAccountReqBody) => {
    const { email, name, password, phoneNumber, level } = value;
    try {
      console.log(`email : ${email}`)
      await createAccount({ email, phoneNumber, password, name, level });
      alert("정상적으로 생성됐습니다.");
      window.location.reload();
    } catch (err) {
      alert(`에러가 발생했습니다. err: ${err}`);
    }
  };

  const onFinishFailed = () => {};

  return (
    <Modal
      title={"어드민 계정추가"}
      visible={isVisible}
      onCancel={modalHandler}
      footer={null}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="이름"
          name="name"
          rules={[{ required: true, message: "이름을 입력하세요!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="이메일"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "이메일 형식을 맞춰주세요",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="휴대폰 번호"
          name="phoneNumber"
          rules={[{ required: true, message: "번호를 입력해주세요!" }]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item */}
        {/* //   label="권한"
        //   name="level"
        //   rules={[{ required: true, message: "권한을 입력해주세요!" }]}
        // >
        //   <Input />
        // </Form.Item> */}

        <Form.Item 
          label="권한"
          name="level"
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="0">0</Radio.Button>
            <Radio.Button value="1">1</Radio.Button>
            <Radio.Button value="2">2</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="패스워드"
          name="password"
          rules={[{ required: true, message: "패스워드를 입력해주세요!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="패스워드 확인"
          name="checkPassword"
          dependencies={["password"]}
          hasFeedback
          rules={[
            { required: true, message: "비밀번호 확인을 입력해주세요." },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("비밀번호가 맞지 않습니다!"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 21, span: 16 }}>
          <Button type="primary" htmlType="submit">
            생성
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddAdminAccountModal;
