import { AdminApi } from "../index";
import axios from "axios";

const shopBaseUrl = "/shop";

export const getAllShopPointInfo = async () => {
  return await AdminApi.get<ShopPointInfoResBody[]>(
    `${shopBaseUrl}/point/info`
  ).then((resp) => resp.data);
};

export const getCafe24Code = async () => {
  return await axios
    .get(
      "https://thesignaturecom.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=cBYjgoYAEJHgNCiiBQvceA&redirect_uri=https%3A%2F%2Fadmin.28vcwallet.com%2Fshop%2Fpoint&scope=mall.read_mileage,mall.write_mileage"
    )
    .then((resp) => resp);
};

export const cancelPointChange = async (
  uid: string,
  hidx: number,
  coinidx: number
) => {
  return AdminApi.post("/shop/point/change/cancel", { uid, hidx, coinidx });
};

export const changePoint = async (body: {
  //git first commit
  uid: string;
  hidx: number;
  coinidx: number;
  type: "increase" | "decrease";
  pt: string;
  memberId: string;
}) => {
  return AdminApi.post("/shop/point/change", body);
};
