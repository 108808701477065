import { AdminApi } from "../index";

export function getAllHomes() {
  return AdminApi.get<HomeResBody[]>(`/home`).then(
    (resp) => resp.data
  );
}

export function getHome(id: number) {
  return AdminApi.get<HomeResBody>(`/home/${id}`).then(
    (resp) => resp.data
  );
}

export function addHome(body: HomeReqBody) {
  return AdminApi.post("/home", body);
}

export function modifyHome(body: HomeReqBody, id: number) {
  return AdminApi.put(`/home/${id}`, body).then((resp) => resp.data);
}

export function deleteHome(id: number) {
  return AdminApi.delete(`/home/${id}`).then((resp) => resp.data);
}

export async function uploadImage(file: FormData) {
  return AdminApi.post<{ url: string; filename: string }>(
    "/image/home",
    file
  ).then((resp) => resp.data);
}
