import { AdminApi } from "../index";

export function getAllTopBanners() {
  return AdminApi.get<TopBannerResBody[]>(`/banner/top`).then(
    (resp) => resp.data
  );
}

export function getTopBanner(id: number) {
  return AdminApi.get<TopBannerResBody>(`/banner/top/${id}`).then(
    (resp) => resp.data
  );
}

export function addTopBanner(body: TopBannerReqBody) {
  return AdminApi.post("/banner/top", body);
}

export function modifyTopBanner(body: TopBannerReqBody, id: number) {
  return AdminApi.put(`/banner/top/${id}`, body).then((resp) => resp.data);
}

export function deleteTopBanner(id: number) {
  return AdminApi.delete(`/banner/top/${id}`).then((resp) => resp.data);
}

export async function getAllMainContents() {
  return await AdminApi.get<MainContentsResBody[]>(
    "/banner/main-contents/hot-active"
  ).then((resp) => resp.data);
}

export async function addMainContent(body: MainContentsReqBody) {
  return await AdminApi.post("/banner/main-contents/hot-active", body);
}

export async function updateMainContent(body: MainContentsReqBody, id: number) {
  return await AdminApi.put(`/banner/main-contents/hot-active/${id}`, body);
}

export async function deleteMainContent(id: number) {
  return await AdminApi.delete(`/banner/main-contents/hot-active/${id}`);
}

export async function getMainContent(id: number) {
  return await AdminApi.get<MainContentsReqBody>(
    `/banner/main-contents/hot-active/${id}`
  ).then((resp) => resp.data);
}

export async function uploadImage(file: FormData) {
  return AdminApi.post<{ url: string; filename: string }>(
    "/image/banner",
    file
  ).then((resp) => resp.data);
}
