import React, { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { Badge, Button, Image, Space } from "antd";
import moment from "moment";


import LoadingPage from "../../components/Loading";

import BaseTable from "../../components/Table/BaseTable";
import AddNewsModal from "../../components/Modal/news/addNews";
import DeleteNewsModal from "../../components/Modal/news/deleteNews";
import ModifyNewsModal from "../../components/Modal/news/modifyNews";
import useModalHandler from "../../hooks/useModalHandler";
import {getAllNewss} from "../../service/News";
import {HeaderContainer} from "../../Accounts";

const NewsPage = () => {
    const [newss, setNewss] = useState<NewsResBody[]>([]);
    const [loading, setLoading] = useState(false);
    const [newsId, setNewsId] = useState<number | null>(null);
    const [
        isVisibleAddNewsModal,
        onChangeAddNewsModal,
        setIsVisibleAddNewsModal,
    ] = useModalHandler(false);

    const [
        isVisibleDeleteNewsModal,
        onChangeDeleteNewsModal,
        setIsVisibleDeleteNewsModal,
    ] = useModalHandler(false);

    const [
        isVisibleUpdateNewsModal,
        onChangeUpdateNewsModal,
        setIsVisibleUpdateNewsModal,
    ] = useModalHandler(false);

    const onDeleteBtnClick = (id: number) => () => {
        setNewsId(id);
        onChangeDeleteNewsModal();
    };

    const onModifyBtnClick = (id: number) => () => {
        setNewsId(id);
        onChangeUpdateNewsModal();
    };

    useEffect(() => {
        bootstrap();
    }, []);

    const newsColumn: ColumnsType<NewsResBody> = [
        { key: "news_no", dataIndex: "news_no", title: "id", width: 50, align: "center" },
        {
            key: "lang",
            dataIndex: "lang",
            title: "언어",
            width: 100,
            align: "center",
        },
        {
            key: "uid",
            dataIndex: "uid",
            title: "uid",
            width: 100,
            align: "center",
        },
        {
            key: "news_title",
            dataIndex: "news_title",
            title: "제목",
            width: 200,
            align: "center",
        },
        {
            key: "news_content",
            dataIndex: "news_content",
            title: "내용",
            width: 400,
            align: "center",
        },
        {
            key: "video",
            dataIndex: "video",
            title: "파일",
            align: "center",
            width: 300,
            render: (v) => 
              v.slice(-3) == 'jpg' || v.slice(-3) == 'png' || v.slice(-4) == 'jpeg' ? ( <Image src={v} />) : (v),
        },
        {
            key: "is_disp",
            dataIndex: "is_disp",
            title: "게시 여부",
            width: 150,
            align: "center",
            render: (val) =>
                val ? (
                    <Badge color="green" text="활성화" />
                ) : (
                    <Badge color="red" text="비활성화" />
                ),
        },
        {
            key: "modify",
            title: "수정 / 삭제",
            width: 150,
            align: "center",
            render: (_, b) => (
                <Space size="middle">
                    <a onClick={onModifyBtnClick(b.news_no)}>수정</a>
                    <a onClick={onDeleteBtnClick(b.news_no)}>삭제</a>
                </Space>
            ),
        },
    ];

    const bootstrap = async () => {
        setLoading(true);
        await getAllNewss().then(setNewss);
        setLoading(false);
    };

    if (loading) return <LoadingPage loading={loading} />;

    return (
        <>
            <HeaderContainer>
                <h2>뉴스 게시글({newss.length})</h2>
                <Button onClick={onChangeAddNewsModal}>뉴스 게시글 추가</Button>
            </HeaderContainer>

            <div>
                <BaseTable
                    datasource={newss.map((b, i) => ({ key: String(i), ...b }))}
                    columnType={newsColumn}
                />
            </div>
            <AddNewsModal
                isVisible={isVisibleAddNewsModal}
                modalHandler={onChangeAddNewsModal}
            />

            <DeleteNewsModal
                isVisible={isVisibleDeleteNewsModal}
                modalHandler={onChangeDeleteNewsModal}
                id={newsId}
            />

            <ModifyNewsModal
                isVisible={isVisibleUpdateNewsModal}
                modalHandler={onChangeUpdateNewsModal}
                id={newsId}
            />
        </>
    );
};

export default NewsPage;

