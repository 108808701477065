import React, { VFC } from "react";
import { DeleteFontStyle } from "../../../styles/common";
import BaseModal from "../BaseModal";
import { CustomModalProps } from "./addBanner";
import { deleteTopBanner } from "../../../service/Banner";

const DeleteTopBannerModal: VFC<CustomModalProps> = ({
  isVisible,
  modalHandler,
  id,
}) => {
  const onDeleteHandler = async () => {
    if (id) {
      try {
        await deleteTopBanner(id);
        alert("정상적으로 삭제 되었습니다.");
        window.location.reload();
      } catch (err) {
        alert("에러가 발생했습니다.");
      }
    }
  };
  return (
    <BaseModal
      isVisible={isVisible}
      handleOk={onDeleteHandler}
      handleCancel={modalHandler}
      headerTitle={"상단배너 삭제"}
    >
      <DeleteFontStyle>
        정말로 <strong>삭제</strong>하시겠습니까?{" "}
      </DeleteFontStyle>
    </BaseModal>
  );
};

export default DeleteTopBannerModal;
