// 사용자 계정 전부 가져오기
import { AdminApi } from "../index";
import moment from "moment";

export async function getUserAccounts() {
  return AdminApi.get<AccountResBody[]>("/users").then((resp) => resp.data);
}

export async function getUsersCount() {
  return AdminApi.get<{ count: string }[]>("/users/count").then(
    (resp) => resp.data
  );
}

export async function getDailyCumulativeUsersCount() {
  return AdminApi.get<DailyCumulativeUsers[]>(
    "/dashboard/accumulation/count"
  ).then((resp) => {
    return resp.data.map((d) => {
      return { date: moment(d.date).format("YYYY-MM-DD"), sum: d.sum };
    });
  });
}
