import { Form, Input, Modal, Image, Button, Row, Col, Switch, Radio } from "antd";
import React, { useEffect, useRef, useState, VFC } from "react";

import useInput from "../../../hooks/useInput";
import { CustomModalProps } from "./addHome";
import {
  getHome,
  modifyHome,
  uploadImage,
} from "../../../service/Home";

const ModifyHomeModal: VFC<CustomModalProps> = ({
  isVisible,
  modalHandler,
  id,
}) => {
  const inputOpenImageRef = useRef<HTMLInputElement>(null);

  const [home, setHome] = useState<HomeResBody | null>(null);
  const [imageUrl, setImageUrl] = useState("");
  const [lang, onChangeLang, setLang] = useInput("");
  const [uid, onChangeUid, setUid] = useInput("");
  const [home_title, onChangeHomeTitle, setHomeTitle] = useInput("");
  const [home_content, onChangeHomeContent, setHomeContent] = useInput("");
  const [dispChecked, setDispChecked] = useState(true);

  const handleImageClick = () => {
    if (inputOpenImageRef && inputOpenImageRef.current) {
      inputOpenImageRef.current.click();
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const formData = new FormData();
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    formData.append("file", e.target.files[0]);

    try {
      const result = await uploadImage(formData);
      const { url } = result;
      setImageUrl(url);
    } catch (err) {
      alert("파일 업로드에 실패했습니다.");
    }
  };

  const onSubmitHandler = async () => {
    const body = {
      lang,
      uid,
      home_title,
      home_content,
      video: imageUrl,
      is_disp: dispChecked,
    };

    try {
      await modifyHome(body, id);
      alert("정상적으로 수정 되었습니다.");
      window.location.reload();
    } catch (err) {
      alert("에러발생");
    }
  };

  useEffect(() => {
    bootstrap();
  }, [isVisible]);

  const bootstrap = async () => {
    let result: HomeResBody;

    if (id) {
      result = await getHome(id);
    }

    if (result) {
      setHome(result);
      const { lang, uid, home_title, home_content, video, is_disp } = result;
      setLang(lang);
      setUid(uid);
      setHomeTitle(home_title);
      setHomeContent(home_content);
      setImageUrl(video);
      setDispChecked(is_disp);
    }
  };

  return (
    <Modal
      title={"홈 게시글 수정"}
      visible={isVisible}
      onCancel={modalHandler}
      footer={null}
    >
      <Form wrapperCol={{ span: 16 }}>
        <Form.Item
          label="언어"
          name="lang"
        >
        <Radio.Group buttonStyle="solid" defaultValue={lang} onChange={onChangeLang}>
          <Radio.Button value="ko">ko</Radio.Button>
          <Radio.Button value="en">en</Radio.Button>
          <Radio.Button value="ja">ja</Radio.Button>
          <Radio.Button value="cn">cn</Radio.Button>
          <Radio.Button value="in">in</Radio.Button>
          <Radio.Button value="vi">vi</Radio.Button>
          <Radio.Button value="th">th</Radio.Button>
        </Radio.Group>
        </Form.Item>
        <div style={{ marginBottom: "10px" }}>
          <span style={{width: "50px", display: "inline-block",}}>uid:</span>
          <Input style={{width: "350px"}} value={uid} onChange={onChangeUid} />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <span style={{width: "50px", display: "inline-block",}}>제목:</span>
          <Input style={{width: "350px"}} value={home_title} onChange={onChangeHomeTitle} />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <span style={{width: "50px", display: "inline-block",}}>내용:</span>
          <textarea style={{width: "350px", height: "88px", maxLines: 6,}} value={home_content} onChange={onChangeHomeContent} />
        </div>

        <div style={{ marginBottom: "20px" }}>
          <span
            style={{
              width: "50px",
              display: "block",
              marginBottom: "10px",
            }}
          >
            파일:
          </span>
          {imageUrl ? (
            <div style={{ display: "flex" }}>
              {imageUrl.slice(-3) == 'png' || imageUrl.slice(-3) == 'jpg' || imageUrl.slice(-4) == 'jpeg' ? 
                (<Image src={imageUrl} />) : (imageUrl)}
              <Button
                onClick={() => {
                  setImageUrl("");
                }}
              >
                X
              </Button>
            </div>
          ) : (
            <Button onClick={handleImageClick}>파일 업로드</Button>
          )}
        </div>

        <Row>
          <Col span={12}>
            <div style={{ marginBottom: "10px" }}>
              <span
                style={{
                  width: "70px",
                  display: "inline-block",
                  marginBottom: "10px",
                }}
              >
                게시 여부
              </span>
              <Switch
                checked={dispChecked}
                onClick={() => {
                  setDispChecked((prevState) => !prevState);
                }}
              />
            </div>
          </Col>
        </Row>

        <Form.Item wrapperCol={{ offset: 21, span: 16 }}>
          <Button type="primary" onClick={onSubmitHandler}>
            수정
          </Button>
        </Form.Item>
      </Form>
      <input
        type="file"
        accept="image/*, video/*"
        style={{ display: "none" }}
        ref={inputOpenImageRef}
        onChange={handleImageUpload}
      />
    </Modal>
  );
};

export default ModifyHomeModal;
