import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Radio,
  Space,
  Button,
  InputNumber,
} from "antd";
import React, { useEffect, useState, VFC } from "react";
import { ColumnsType } from "antd/es/table";
import BaseTable from "../../Table/BaseTable";
import {
  createUserCoinLockBalance,
  deleteUserCoinLockBalance,
  getUserCoinLockBalance,
  getUserCoinLockBalances,
  updateUserCoinLockBalance,
} from "../../../service/CoinInfo";
import { convertBalance, parseDateTime } from "../../../utils/date";
import useInput from "../../../hooks/useInput";
import useModalHandler from "../../../hooks/useModalHandler";
import DeleteBaseModal from "../BaseModal/deleteBaseModal";

const UserBalanceListModal: VFC<ModalProps> = ({
  isVisible,
  modalHandler,
  id,
  coinIndex,
}) => {
  const [balances, setBalances] = useState<UserCoinBalanceLockResType[]>([]);
  const [type, setType] = useState(-1);
  const [message, onChangeMessage, setMessage] = useInput("");
  const [balance, onChangeBalance, setBalance] = useInput("");
  const [mode, setMode] = useState<"read" | "update" | "create">("read");
  const [selectId, setSelectId] = useState(-1);
  const [uid, setUid] = useState("");
  const [coinidx, setCoinIdx] = useState(-1);

  const [deleteModal, onChangeDeleteModal, setDeleteModal] = useModalHandler(
    false
  );

  const onDeleteHandler = async () => {
    if (uid && coinidx) {
      try {
        await deleteUserCoinLockBalance(selectId, uid, coinidx);
        alert("정상적으로 삭제 되었습니다.");
        await getUserCoinLockBalances(id).then(setBalances);
        onChangeDeleteModal();
      } catch (err) {
        console.log(err);
        alert("요청에 실패했습니다.");
      }
    }
  };

  const columns: ColumnsType<UserCoinBalanceLockResType> = [
    {
      key: "type",
      dataIndex: "type",
      title: "타입",
      align: "center",
      render: (value) => {
        switch (value) {
          case 0:
            return "잠금";
          case 1:
            return "스테이킹";
          case 2:
            return "해제";
          case 3:
            return "스테이킹 해제";
        }
      },
    },

    {
      key: "balance",
      dataIndex: "balance",
      title: "잔액",
      align: "center",
      render: (_, bal) => <>{convertBalance(bal.balance)}</>,
    },

    {
      key: "message",
      dataIndex: "message",
      title: "메세지",
      align: "center",
    },

    {
      key: "timestamp",
      dataIndex: "timestamp",
      title: "생성일",
      align: "center",
      render: (_, bal) => <>{parseDateTime(bal.timestamp)}</>,
    },
    {
      key: "modify",
      title: "수정 / 삭제",
      align: "center",
      render: (_, b) => (
        <Space size="middle">
          {/*<a*/}
          {/*  onClick={async () => {*/}
          {/*    // setSelectId(b.idx);*/}
          {/*    // setCoinIdx(b.coinidx);*/}
          {/*    // setUid(b.uid);*/}
          {/*    setMode("create");*/}
          {/*    setMessage("");*/}
          {/*    setType(-1);*/}
          {/*    setBalance("");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  추가*/}
          {/*</a>*/}
          <a
            onClick={async () => {
              setSelectId(b.idx);
              setCoinIdx(b.coinidx);
              setUid(b.uid);
              const result = await getUserCoinLockBalance(b.idx);
              setMessage(result.message ? result.message : "");
              setType(result.type);
              setBalance(result.balance);
              setMode("update");
            }}
          >
            수정
          </a>
          <a
            onClick={async () => {
              setSelectId(b.idx);
              setCoinIdx(b.coinidx);
              setUid(b.uid);
              onChangeDeleteModal();
            }}
          >
            삭제
          </a>
        </Space>
      ),
    },
  ];

  const onUpdateHandler = async () => {
    const body = { type, balance: Number(balance), message, uid, coinidx };
    const createBody = {
      type,
      balance: Number(balance),
      message,
      uid: id,
      coinidx: coinIndex,
    };

    try {
      switch (mode) {
        case "update":
          await updateUserCoinLockBalance(selectId, body);
          alert("정상적으로 수정되었습니다.");
          await getUserCoinLockBalances(id).then(setBalances);
          setMode("read");
          setMessage("");
          setType(-1);
          setBalance("");
          return;
        case "create":
          if (type > -1) {
            await createUserCoinLockBalance(createBody);
            alert("정상적으로 수정되었습니다.");
            await getUserCoinLockBalances(id).then(setBalances);
            setMode("read");
            setMessage("");
            setType(-1);
            setBalance("");
          } else {
            alert("입력값을 확인해주세여.");
          }

          return;
      }
    } catch (err) {
      alert("에러가 발생했습니다. 다시 확인해주세요");
    }
  };

  const bootstrap = async () => {
    if (id) {
      await getUserCoinLockBalances(id).then(setBalances);
    }
  };

  useEffect(() => {
    bootstrap();
  }, [isVisible]);

  console.log();

  return (
    <>
      <Modal
        title="history 확인"
        visible={isVisible}
        onCancel={() => {
          setMode("read");
          setMessage("");
          setType(-1);
          setBalance("");
          modalHandler();
        }}
        footer={null}
        width={1200}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <Col>
            <Button
              onClick={async () => {
                setMode("create");
                setMessage("");
                setType(-1);
                setBalance("");
              }}
            >
              추가하기
            </Button>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={8} md={8} sm={24}>
            <Form>
              <div style={{ marginBottom: "10px" }}>
                <span
                  style={{
                    width: "100px",
                    display: "inline-block",
                    marginBottom: "10px",
                  }}
                >
                  타입:
                </span>
                <Radio.Group
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  disabled={mode === "read"}
                >
                  <Radio value={0}>잠금</Radio>
                  <Radio value={1}>스테이킹</Radio>
                  <Radio value={2}>해제</Radio>
                  <Radio value={3}>스테이킹 해제</Radio>
                </Radio.Group>
              </div>

              <div style={{ marginBottom: "10px" }}>
                <span
                  style={{
                    width: "100px",
                    display: "inline-block",
                    marginBottom: "10px",
                  }}
                >
                  메세지:
                </span>
                <Input
                  disabled={mode === "read"}
                  value={message}
                  onChange={onChangeMessage}
                />
              </div>

              <div style={{ marginBottom: "10px" }}>
                <span
                  style={{
                    width: "100px",
                    display: "inline-block",
                    marginBottom: "10px",
                  }}
                >
                  잔액:
                </span>
                <Input
                  disabled={mode === "read"}
                  value={balance}
                  onChange={onChangeBalance}
                  type="number"
                />
              </div>

              {mode !== "read" ? (
                <div>
                  <Button
                    type="primary"
                    style={{ marginRight: "16px", marginTop: "20px" }}
                    onClick={onUpdateHandler}
                  >
                    {mode === "update" ? "수정하기" : "추가하기"}
                  </Button>
                  <Button
                    onClick={() => {
                      setMode("read");
                      setMessage("");
                      setType(-1);
                      setBalance("");
                    }}
                    type="primary"
                  >
                    {mode === "update" ? "수정 취소하기" : "추가 취소하기"}
                  </Button>
                </div>
              ) : null}
            </Form>
          </Col>

          <Col lg={16} md={16} sm={24}>
            <BaseTable datasource={balances} columnType={columns} />
          </Col>
        </Row>
      </Modal>
      <DeleteBaseModal
        onSubmitHandler={onDeleteHandler}
        title={"삭제"}
        isVisible={deleteModal}
        modalHandler={onChangeDeleteModal}
      />
    </>
  );
};

export default UserBalanceListModal;
